var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BT-Fast-Start-Banner'),_c('BT-Blade-Items',{attrs:{"addBladeName":"product","bladeName":"products","bladesData":_vm.bladesData,"canDelete":"","canExportCSV":"","canSearchLocal":"","clearBlades":"","dependantBlades":[{ bladeName: 'product' }],"getSelectBladeData":function (bladeData) { return { productID: bladeData.data != null ? bladeData.data.id : null } },"headers":[
            { text: 'Product Name', value: 'productName', title: 1, searchable: true },
            { text: 'Abbreviation', value: 'abbreviation', subtitle: 1, searchable: true },
            { text: 'Sort Number', value: 'sortNumber', searchable: true },
            { text: 'Weight (Kg)', value: 'weight', textFilter: 'toDisplayNumber' },
            { text: 'Sellable', value: 'isSold', bool: true }],"navigation":"products","onFilter":function (list) { return list.orderBy('sortNumber') },"syncNavigation":"product-syncing","title":"Products","useServerPagination":""},scopedSlots:_vm._u([{key:"settings",fn:function(){return [_c('v-list-item',{attrs:{"to":{ name: 'products-board' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-dashboard-variant-outline")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Board")])],1)],1)]},proxy:true},{key:"toolbar-right-far",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'new-products' }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]},proxy:true},{key:"actionsRight",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.integrationToggle = !_vm.integrationToggle}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-connection")])],1)]},proxy:true},{key:"bottom",fn:function(){return [_c('BT-Sidebar-External-Integration',{attrs:{"navigation":"products","showToggle":_vm.integrationToggle,"sidebarLabel":"Sync With","startOpen":_vm.integrationToggle,"syncNavigation":"product-syncing"},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.productName))])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }