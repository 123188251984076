<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Items
            addBladeName="product"
            bladeName="products"
            :bladesData="bladesData"
            canDelete
            canExportCSV
            canSearchLocal
            clearBlades
            :dependantBlades="[{ bladeName: 'product' }]"
            :getSelectBladeData="bladeData => { return { productID: bladeData.data != null ? bladeData.data.id : null } }"
            :headers="[
                { text: 'Product Name', value: 'productName', title: 1, searchable: true },
                { text: 'Abbreviation', value: 'abbreviation', subtitle: 1, searchable: true },
                { text: 'Sort Number', value: 'sortNumber', searchable: true },
                { text: 'Weight (Kg)', value: 'weight', textFilter: 'toDisplayNumber' },
                { text: 'Sellable', value: 'isSold', bool: true }]"
            navigation="products"
            :onFilter="list => { return list.orderBy('sortNumber') }"
            syncNavigation="product-syncing"
            title="Products"
            useServerPagination>
            <template v-slot:settings>
                <v-list-item :to="{ name: 'products-board' }">
                    <v-list-item-icon>
                        <v-icon small>mdi-view-dashboard-variant-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>Board</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:toolbar-right-far>
                <v-btn small icon :to="{ name: 'new-products' }">
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
                <!-- <BT-Menu
                    hideHeader
                    icon="mdi-plus"
                    small>
                    <v-list-item :to="{ name: 'customer', params: { id: 'new' } }">
                        <v-list-item-content>
                            <v-list-item-title>New Customer</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'new-customers' }">
                        <v-list-item-content>
                            <v-list-item-title>Muliple Customers</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </BT-Menu> -->
            </template>
            <template v-slot:actionsRight>
                <v-btn
                    small
                    icon
                    @click="integrationToggle = !integrationToggle">
                    <v-icon small>mdi-connection</v-icon>
                </v-btn>
            </template>
            <template v-slot:bottom>
                <BT-Sidebar-External-Integration
                    navigation="products"
                    :showToggle="integrationToggle"
                    sidebarLabel="Sync With"
                    :startOpen="integrationToggle"
                    syncNavigation="product-syncing">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-title>{{ item.productName }}</v-list-item-title>
                    </template>
                </BT-Sidebar-External-Integration>
            </template>
        </BT-Blade-Items>
    </div>
</template>

<script>
export default {
    name: 'Products-Blade',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTSidebarExternalIntegration: () => import('~components/BT-Sidebar-External-Integration.vue'),
    },
    data: function() {
        return {
            integrationToggle: false,
        }
    },
    props: {
        bladesData: null
    },
    mounted() {
        var q = this.$route.query;
        if (q) {
            if (q.integrate != null) {
                this.integrationToggle = !this.integrationToggle;
            }
        }
    }
}
</script>